.pk-header-primary {
  position: relative;
  margin-bottom: 50px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }
  .pk-header-primary:after {
    position: absolute;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    background: #FFCC00;
    width: 80px;
    height: 5px;
    border-radius: 5px;
    display: block; }

.pk-header-second {
  position: relative;
  margin-bottom: 30px;
  font-weight: 700; }
  .pk-header-second:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -20px;
    background: #FFCC00;
    width: 25px;
    height: 5px;
    border-radius: 5px;
    display: block; }

.pk-btn-primary {
  background: #383838;
  color: white;
  font-weight: 700;
  text-transform: uppercase; }
  .pk-btn-primary:hover, .pk-btn-primary:focus {
    text-decoration: none;
    color: white;
    background: #2b2b2b; }

.pk-btn-second {
  background: #e6e6e6;
  color: #383838;
  font-weight: 700;
  text-transform: uppercase; }
  .pk-btn-second:hover, .pk-btn-second:focus {
    text-decoration: none;
    color: #383838;
    background: #d9d9d9; }

.pk-btn-main {
  background: #FFCC00;
  color: #231f20;
  font-weight: 700;
  text-transform: uppercase; }
  .pk-btn-main:hover, .pk-btn-main:focus {
    text-decoration: none;
    color: #231f20;
    background: #e6b800; }

footer .pk-navigation {
  padding: 50px 0px;
  background: #f3f3f3; }
  footer .pk-navigation .logo img {
    height: 170px; }
  @media screen and (max-width: 991px) {
    footer .pk-navigation .info {
      text-align: center; }
      footer .pk-navigation .info .pk-header-second {
        margin-top: 30px; }
        footer .pk-navigation .info .pk-header-second:after {
          left: 50%;
          transform: translateX(-50%); } }
  footer .pk-navigation .info p {
    margin-bottom: 0px; }
    footer .pk-navigation .info p a {
      font-weight: 700; }
  footer .pk-navigation .info .js-copyToClipboard {
    cursor: pointer;
    position: relative; }
    footer .pk-navigation .info .js-copyToClipboard .copy-toast {
      position: absolute;
      left: 50%;
      background: #383838;
      border-radius: 5px;
      color: white;
      font-weight: 700;
      padding: 4px 7px;
      width: 88px;
      top: 20px;
      text-transform: uppercase;
      transform: translateX(-50%);
      font-family: 'Lato';
      font-size: calc(9px + 1 * ( (100vw - 320px) / 1600)); }
      @media screen and (max-width: 320px) {
        footer .pk-navigation .info .js-copyToClipboard .copy-toast {
          font-size: 9px; } }
      @media screen and (min-width: 1920px) {
        footer .pk-navigation .info .js-copyToClipboard .copy-toast {
          font-size: 10px; } }

footer .pk-copyright {
  background: #383838;
  padding: 20px 0px;
  position: relative;
  color: white;
  font-size: calc(10px + 1 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    footer .pk-copyright {
      font-size: 10px; } }
  @media screen and (min-width: 1920px) {
    footer .pk-copyright {
      font-size: 11px; } }
  @media screen and (max-width: 991px) {
    footer .pk-copyright {
      text-align: center; } }
  footer .pk-copyright img {
    height: 20px;
    margin-top: -5px; }
  footer .pk-copyright ul {
    display: flex; }
    @media screen and (max-width: 991px) {
      footer .pk-copyright ul {
        margin-top: 10px; } }
    footer .pk-copyright ul li {
      margin-right: 15px; }
      footer .pk-copyright ul li:last-child {
        margin-right: 0px; }
  footer .pk-copyright a {
    color: white;
    font-weight: 700; }
    footer .pk-copyright a:hover {
      color: white; }
